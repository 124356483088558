import { Outlet } from "react-router-dom";
import React, { memo, useCallback, useContext, useState } from "react";
import Header from "../components/header/Header";
import SideNav from "../components/sidenav/SideNav";
import Footer from "../components/footer/Footer";
import MaintenanceModal from "../components/maintenance-modal/MaintenanceModal";
import {
  PropertyStoreContext,
  PropertyStoreProvider,
} from "../contexts/PropertyStoreContext";

const MainContainer = () => {
  const { selectedProperty } = useContext(PropertyStoreContext);
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, [setIsOpen]);

  return (
    <>
      {selectedProperty ? (
        <>
          <SideNav isOpen={isOpen} toggleSidebar={toggleSidebar} />
          <main className={isOpen ? "content expanded" : "content"}>
            <Outlet />
            <div
              className="content-overlay"
              role="none"
              onClick={toggleSidebar}
              onKeyDown={toggleSidebar}
            ></div>
            <MaintenanceModal />
          </main>
        </>
      ) : (
        <p style={{ margin: "40px 60px" }}>Select a property</p>
      )}
    </>
  );
};

const RootLayout = () => {
  return (
    <div className="App">
      <PropertyStoreProvider>
        <Header />
        <div className="main-container">
          <MainContainer />
        </div>
        <Footer />
      </PropertyStoreProvider>
    </div>
  );
};

export default memo(RootLayout);
