import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";

const DOWNLOAD_FILE = "DOWNLOAD_FILE/";

const createAxiosInstance = (baseURL) => {
  const HEADERS = {
    "Content-Type": "application/json",
  };

  const axiosInstance = axios.create({
    headers: HEADERS,
    baseURL,
  });

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      let token = localStorage.getItem("ROCP_idToken");
      token = token?.replaceAll('"', "") ?? "";
      config.headers.authorization = `Bearer ${token}`;

      // If the URL starts with "/downloadFile", set the responseType to "blob"
      // and remove the "/downloadFile" prefix from the URL.
      if (config.url.startsWith(DOWNLOAD_FILE)) {
        config.url = config.url.replace(DOWNLOAD_FILE, "");
        config.responseType = "blob";
      }
      return config;
    },
    (error) => {
      // Handle request error
      return Promise.reject(error);
    }
  );

  const handleError = (error) => {
    const { response, message } = error;
    if (response) {
      const { status, statusText, data } = response;
      switch (status) {
        case 401:
          return handleInvalidSession({ statusText });

        case 403:
          return handleInvalidSession({
            statusText,
            noRedirectToPreviousPage: true,
          });

        case 417:
          if (data) {
            return data;
          }
          break;

        case 500:
          toast.error(
            <div>
              <div>
                <b>Message:</b> {statusText}
              </div>
              <div>
                <b>Status code:</b> {status}
              </div>
              <div>{data.message || data.error || JSON.stringify(data)}</div>
            </div>
          );
          return statusText;

        default:
          toast.error(data?.message || statusText);
      }
    }
    return message;
  };

  const handleRequest = async (method, path, data) => {
    const payload = { ...data };
    const token = localStorage.getItem("ROCP_idToken");
    if (payload.password) {
      delete payload.password;
    }

    const r7Object = {
      user_id: jwtDecode(token).email,
      method: method,
      type: "Request",
      api_end_point: path,
      page_url: window.location.href,
      api_req_payload: JSON.stringify(payload),
      correlation_id: Date.now(),
    };
    try {
      window.R7Insight.log(r7Object);
      const response = await axiosInstance[method](path, data);
      window.R7Insight.log({
        ...r7Object,
        type: "Response",
        status_code: response.status,
        api_res_payload: JSON.stringify(response.data),
      });
      return { ...response, body: response.data };
    } catch (error) {
      const { response, message } = error;
      window.R7Insight.error({
        ...r7Object,
        type: "Error",
        status_code: response.status,
        error_desc: message,
        api_res_payload: JSON.stringify(response.data),
      });
      throw handleError(error);
    }
  };

  const handleRequestForFile = async (method, path, data) => {
    return handleRequest(method, DOWNLOAD_FILE + path, data);
  };

  const handleInvalidSession = ({ statusText, noRedirectToPreviousPage }) => {
    toast.error("Invalid session, Please login to continue");
    sessionStorage.clear();

    if (window && window.location && window.location.assign) {
      if (noRedirectToPreviousPage) {
        window.location.assign("/logout");
      } else {
        window.location.assign(
          "/logout?go2=" +
            encodeURIComponent(
              window.location.pathname + window.location.search
            )
        );
      }
    }
    return statusText;
  };

  return {
    getRequest: (path) => handleRequest("get", path),
    postRequest: (path, data) => handleRequest("post", path, data),
    putRequest: (path, data) => handleRequest("put", path, data),
    delRequest: (path) => handleRequest("delete", path),
    getFileRequest: (path) => handleRequestForFile("get", path),
  };
};

export default createAxiosInstance;
