import React, { useCallback, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "./maintenanceModal.scss";

const MaintenanceModal = () => {
  const endDate = new Date("2024-12-09");
  const [isOpen, setIsOpen] = useState(endDate > new Date());

  const toggleModal = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, [setIsOpen]);

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      centered
      backdrop="static"
      className="maintenance-modal"
    >
      <ModalHeader>
        <i className="ti-alert fa-2x" aria-hidden="true"></i>
        <p>{"Attention"}</p>
      </ModalHeader>
      <ModalBody className="container1 text-center">
        <div className="row">
          <span className="col">
            {"Please be advised that starting at 1 AM EST on "}
            <b>{"Sunday, December 8 "}</b>
            {
              "our system will undergo system maintenance for approximately 4 to 8 hours."
            }
          </span>
        </div>
        <div className="row">
          <span className="col">
            {
              "During this time, our email services will be unavailable. For urgent assistance, please contact our Customer Support directly via phone on "
            }
            <b>{"(646) 847-9944"}</b>
          </span>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggleModal}>
          {"Continue"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default MaintenanceModal;
